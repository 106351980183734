@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
}
html {
  transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
}
body {
  transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.shadow-all-sides {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Adjust shadow color and size as needed */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s linear infinite;
}
.homeScreen {
  height: calc(100% - 48px);
}

input.p-inputtext {
  outline: none !important;
}
.p-inputtext:focus,
.p-inputtext:active {
  outline: none;
  right: none;
}

.p-inputtext .p-component .p-inputtext .p-component {
  outline: none;
}
.sliderClass {
  height: 1px;
  gap: 10px;
  border: 0px 0px 1px 0px;
  -webkit-appearance: none;
  width: 50%;
  background: #d8dadc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.sliderClass::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #024773;
  cursor: pointer;
  /* margin-left: -25.5px; */
}
.sliderClass::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #024773;
  cursor: pointer;
}

.invitiesSlider {
  height: 5px;
  gap: 10px;
  border: 0px 0px 1px 0px;
  -webkit-appearance: none;
  width: 50%;
  background: #ffffff;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.invitiesSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #024773;
  cursor: pointer;
  /* margin-left: -25.5px; */
}

.invitiesSlider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #024773;
  cursor: pointer;
}

.p-datepicker-title {
  display: contents;
}

.p-datepicker {
  min-width: auto !important;
  left: 660px !important;
}

.custom-calendar-panel .p-datepicker .p-datepicker-content {
  overflow-y: auto;
}

.tooltip {
  visibility: hidden;
  width: 30px;
  background-color: #024773;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #024773 transparent;
}

.tooltip.show {
  visibility: visible;
}

.react-ios-time-picker-container {
  background-color: white !important;
}

.react-ios-time-picker-btn-container {
  background-color: white !important;
}

.react-ios-time-picker-btn {
  color: black !important;
}

.react-ios-time-picker {
  margin-left: 18rem !important;
  margin-bottom: 100px !important;
}

.react-ios-time-picker-input {
  font-size: 0.8rem !important;
  padding: 0.5rem !important;
  width: 40vh;
}

.react-ios-time-picker-input {
  border: none !important;
}

@media (max-width: 768px) {
  /* Adjust left position for smaller screens */
  .p-datepicker {
    left: 10vw !important;
  }
}

@media (min-width: 769px) {
  /* Adjust left position for medium-sized screens */
  .p-datepicker {
    left: 45vw !important;
  }
}

@media (max-width: 768px) {
  /* Adjust left position for smaller screens */
  .react-ios-time-picker {
    margin-left: 0rem !important;
  }
}

/* Add this to your CSS file */
.backdrop-blur {
  backdrop-filter: blur(5px);
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #014774;
  border-radius: 50px;
  background-image: -webkit-linear-gradient(    45deg,    rgba(255, 255, 255, 0.2) 25%,    transparent 25%,    transparent 50%,    rgba(255, 255, 255, 0.2) 50%,    rgba(255, 255, 255, 0.2) 75%,    transparent 75%,    transparent  );
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}


