.gridient-button {
  /* background-image: url("../../Asset/Svg/buttons_bg.svg"); Path to your image */
  /* background-size: cover; Adjust as necessary */
  /* background-repeat: no-repeat; Ensure the image doesn't repeat */
  /* background-position: center; Center the background image */
  background-color: #0074a1;
  margin: 10px;
  padding: 5px 20px;
  text-align: center;
  color: white;
  border-radius: 12px;
  display: block;
}

.normal-button {
  color: #1385ac;
  border: 1px solid #1385ac;
  margin: 10px;
  padding: 5px 15px;
  text-align: center;
  background-size: 200% auto;
  border-radius: 12px;
  display: block;
}
